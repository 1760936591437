import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { SourceValueDetail, SourceValueView } from './components';
import List from 'common/List';

import { actions } from '../../_model/source_value/actions';
import { Card, CardContent, CardHeader, FormControl, Grid, Input, InputLabel, MenuItem, Paper, Select, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, makeStyles, useTheme } from '@material-ui/core';



const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
    maxWidth: 300,
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  chip: {
    margin: 2,
  },
  noLabel: {
    marginTop: theme.spacing(3),
  },
}));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const names = [
  'facebook',
  'facebook_ads_library',
  'googlenews',
  'google_pagespeed',
  'google_pagespeed_desktop',
  'google_pagespeed_mobile',
  'instagram',
  'phlanx',
  'pingdom',
  'semrush',
  'semrush_api_overview',
  'semrush_api_backlinks',
  'similarweb',
  'uptrends',
  'wappalyzer',
  'woorank'
];

function getStyles(name, sources, theme) {
  return {
    fontWeight:
      sources.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

const SourceValue = () => {

  const { source_value_id } = useParams();
  const title = 'Source Value';
  const filter_param = 'source_value_id';

  const repo = useSelector(state => state.source_value);
  const [recordsData, setRecordsData] = useState([]);
  const [headersData, setHeadesData] = useState([]);

  const classes = useStyles();
  const theme = useTheme();
  const [sources, setSources] = useState([]);


  useEffect(() => {
    setRecordsData(repo.items);
  }, [repo]);

  useEffect(() => {
    if (recordsData.length == 0) return;
    setHeadesData(Object.keys(recordsData[0]));
  }, [recordsData]);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(actions.getAll({ sources: sources }, 1, 100));
  }, [sources]);


  const getCellStyle = (error) => {

    return {
      border: "1px solid #ddd",
    };

  }




  const handleChange = (event) => {
    setSources(event.target.value);
  };

  const handleChangeMultiple = (event) => {
    const { options } = event.target;
    const value = [];
    for (let i = 0, l = options.length; i < l; i += 1) {
      if (options[i].selected) {
        value.push(options[i].value);
      }
    }
    setSources(value);
  };

  return (
    <>

      <Grid item xs={12} >
        <Card>
          <CardHeader title="Source values"
            subheader={<FormControl className={classes.formControl}>
              <InputLabel id="demo-mutiple-name-label">Source</InputLabel>
              <Select
                labelId="demo-mutiple-name-label"
                id="demo-mutiple-name"
                multiple
                value={sources}
                onChange={handleChange}
                input={<Input />}
                MenuProps={MenuProps}
              >
                {names.map((name) => (
                  <MenuItem key={name} value={name} style={getStyles(name, sources, theme)}>
                    {name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>} ></CardHeader>

          <CardContent>
            <TableContainer component={Paper}>
              <Table aria-label="simple table">
                <TableHead>
                  <TableRow>
                    {headersData && headersData.map((field, key) => (
                      <TableCell key={key} >
                        {field}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {recordsData && recordsData.map((row, key) => (
                    <TableRow key={key}>
                      {row && Object.keys(row).map((field, key) => (
                        <TableCell style={getCellStyle(field.error)} key={key} scope="row">
                          {typeof row[field] == "boolean" && row[field] ? 'YES' : row[field]}
                        </TableCell>
                      ))}
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>

          </CardContent>
        </Card>
      </Grid>
    </>
  );
};

export default SourceValue;
