/* eslint-disable no-console */
import React, { useEffect } from 'react';
import {  useHistory, withRouter } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { actions as authActions } from '../../_model/authentication/actions';
import { useSelector } from 'react-redux';

const SignOut = props => {

  const loggedIn = useSelector(state => state.authentication.loggedIn);

  const history = useHistory();
  useEffect(() => {
    if (!loggedIn) {
      history.push('/');
    }
  }, [loggedIn]);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(authActions.logout());
  }, []);

  return null;

};



export default withRouter(SignOut);

