import React, { useEffect } from 'react';
import { Card, CardContent, CardHeader, Grid, Typography } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import Company from 'views/Company/Company';
import CompanyClusterSetting from 'views/CompanyClusterSetting';
import { actions } from '_model/company_cluster';
import { actions as filterActions } from '_model/filter';


const CompanyClusterView = ({ id }) => {

  const item = useSelector(state => state.company_cluster.item);

  const dispatch = useDispatch();
  
  useEffect(() => {
    if (!id) return;
    dispatch(actions.getOne(id));

    let filter = {};
    filter['cluster_id'] = id;
    dispatch(filterActions.addFilter(filter))

  }, [id]);

  if (!item) return null;

  return (

    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Card>
          <CardHeader title="Clusters" />
          <CardContent>
          <Typography>{item.name}</Typography>
          </CardContent>
        </Card>
      </Grid>

      <Grid item xs={12}>
        <CompanyClusterSetting clusterId={id}/>
      </Grid>

      <Grid item xs={12}>
        <Company />
      </Grid>
    </Grid>


  );
}


export default CompanyClusterView;
