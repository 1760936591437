import { Card, CardContent, CardHeader, Grid, Typography } from '@material-ui/core';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Screenshot } from 'views';
import { actions } from '_model/config_period';
import { actions as filterActions } from '_model/filter';

const ConfigPeriodView = ({ id }) => {

  const item = useSelector(state => state.config_period.item);
  const dispatch = useDispatch();
  useEffect(() => {
    if (!id) return;dispatch(actions.getOne(id));
    let filter = {};
    filter['period_id'] = id;
    dispatch(filterActions.addFilter(filter));

    return () => {
      dispatch(filterActions.removeFilter('period_id'));
    };

  }, [id]);

  if (!item) return null;

  return (

    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Card>
          <CardHeader title="Period" />
          <CardContent>
          <Typography>#{item.id} {item.name} - {item.weight}</Typography>
          </CardContent>
        </Card>
      </Grid>

      <Grid item xs={12}>
        <Screenshot></Screenshot>
      </Grid>
    </Grid>


  );
}


export default ConfigPeriodView;
