import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/styles';
import { useDispatch, useSelector } from 'react-redux';
import { actions,  } from '../../../_model/config_period/actions';
import { AppBar, Card, CardContent, CardHeader, Grid, TablePagination, Toolbar } from '@material-ui/core';
import ItemList from 'common/ItemList';
// import { configActions } from '_actions/config.actions';
import { ConfigPeriodToolbar } from '.';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(0),
    backgroundColor: "white",
  },
  content: {
    marginTop: theme.spacing(0)
  },
  footerAppBar: {
    flexGrow: 1,
    [theme.breakpoints.up('lg')]: {
      width: 'calc(100% - 240px)'
    },
    top: 'auto',
    bottom: 0,
  },
}));



const ConfigPeriodList = () => {
  const config_period = useSelector(state => state.config_period);
  const dispatch = useDispatch();
  

  
 
  useEffect(() => {
    dispatch(actions.getAll());
  }, []);


  // item mapping



  const itemMapper = (item) => {
    return {
      id: item.id,
      action :  "/config-period/" + item.id,
      primary: `${item.name}`,
      secondary: `#${item.id}`,
    };
  }


  const handleClick = (id) => {
    dispatch(actions.showNew(id));
  }
  return (
    <Grid container spacing={2}>
    <Grid item xs={12}>

      <Card>
        <CardHeader title="Period" action={<ConfigPeriodToolbar />} ></CardHeader>
        <CardContent>
        <ItemList items={config_period.items ?? []} itemMapper={itemMapper} 
        onClick={handleClick}
         actionPath="/config-period/"/>
      </CardContent>
      </Card>
      </Grid>
    </Grid>
  );
};

export default ConfigPeriodList;



