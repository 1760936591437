import React, { Fragment, useEffect } from 'react';

import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { Toolbar,  Hidden, IconButton,  Dialog, DialogTitle, List, ListItem, DialogActions, Button,  Divider,  FormControlLabel, Checkbox,  DialogContent } from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import { CurrentPageName } from '../Sidebar/Sidebar';
import { useState } from 'react';
import CollapsableAppBar from './CollapsableAppBar';
import { useDispatch, useSelector } from 'react-redux';
// import { configActions } from '_actions/config.actions';

import { useLocation } from 'react-router';

// import { ProjectToolbar } from 'views/Project/components';
// import { CompanyToolbar } from 'views/Company/components';
// import { AgencyToolbar } from 'views/Agency/components';
// import { CompanyClusterToolbar } from 'views/CompanyCluster/components';
// import { CompanyClusterSettingToolbar } from 'views/CompanyClusterSetting/components';




function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const useStyles = makeStyles(theme => ({
  root: {
    boxShadow: 'none'
  },
  flexGrow: {
    flexGrow: 1
  },

  signOutButton: {
    marginLeft: theme.spacing(1)
  },
  fabProgress: {
    color: "#FFFFFF",
  },
  searchInput: {
    width: '20px'
  }
}));

const Topbar = props => {
  const { className, onSidebarOpen, ...rest } = props;

  const classes = useStyles();

  const [open, setOpen] = useState(false);

  const handleClose = () => {
    setOpen(false);
  };


  const dispatch = useDispatch();
  const query = useQuery();
  const activeSearchBox = null;


  useEffect(() => {
    const sts = query.get('ls');
    if (sts) {
      // dispatch(configActions.setLeadSearchStatuses([sts]));
    }
  }, []);

  
  const leadSearchStatuses = [];

  
  const handleLeadSearchStatusChange = event => {

    const checked = event.target.checked;
    const value = event.target.value;

    let sts = [...leadSearchStatuses];

    if (sts.indexOf(value) >= 0) {
      sts.splice(sts.indexOf(value), 1);
    }

    if (sts.every((el) => { value.includes(el) })) {

    }

    if (checked) {
      sts.push(value);
    }

    // dispatch(configActions.setLeadSearchStatuses(sts));
  };

  const getLeadStautsChecked = (status) => {

    const val = leadSearchStatuses.includes(status);
    return val;

  }

  useEffect(() => {
    const sts = query.get('ms');
    if (sts) {
      // dispatch(configActions.setMessageSearchStatuses([sts]));
    }
  }, []);


  const toolbar = useSelector(state => state.topbar.toolbar);
  

  return (
    <CollapsableAppBar>
      <Toolbar disableGutters={true}>

        <Hidden lgUp>
          <IconButton
            color="inherit"
            onClick={onSidebarOpen}
          >
            <MenuIcon />
          </IconButton>
        </Hidden>
        <CurrentPageName></CurrentPageName>
        <div className={classes.flexGrow} />
        <Dialog
          //onClose={handleClose} 
          aria-labelledby="simple-dialog-title" open={open} >
          <DialogTitle id="simple-dialog-title">Users Filters</DialogTitle>
          <DialogContent>
            <List>
              <ListItem>
              </ListItem>
              <ListItem>
              </ListItem>
            </List>
          </DialogContent>
          {activeSearchBox === 'lead' && (
            <Fragment>
              <Divider></Divider>
              <DialogTitle id="simple-dialog-title">Status Advanced Filters</DialogTitle>
              <DialogContent>


               


              </DialogContent>
            </Fragment>
          )}


          <DialogActions> <Button onClick={handleClose} color="primary">
            Close
            </Button></DialogActions>
        </Dialog>
      
      </Toolbar>


      {/* {toolbar === 'agency' && (
        <AgencyToolbar></AgencyToolbar>

      )}

      {toolbar === 'project' && (
        <ProjectToolbar></ProjectToolbar>

      )}


      {toolbar === 'company_cluster' && (
        <CompanyClusterToolbar></CompanyClusterToolbar>

      )}

      {toolbar === 'company_cluster_setting' && (
        <CompanyClusterSettingToolbar />

      )}

      {toolbar === 'company' && (
        <CompanyToolbar></CompanyToolbar>

      )} */}
    

    </CollapsableAppBar>

  );
};

Topbar.propTypes = {
  className: PropTypes.string,
  onSidebarOpen: PropTypes.func
};

export default Topbar;
