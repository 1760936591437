import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/styles';
import { useDispatch, useSelector } from 'react-redux';
import { actions, actions as company_clusterActions } from '../../../_model/company_cluster_setting_kpi/actions';
import { AppBar, Card, CardContent, CardHeader, Grid, TablePagination, Toolbar } from '@material-ui/core';
import ItemList from 'common/ItemList';
// import { configActions } from '_actions/config.actions';
import { CompanyClusterSettingKpiToolbar } from '.';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(0),
    backgroundColor: "white",
  },
  content: {
    marginTop: theme.spacing(0)
  },
  footerAppBar: {
    flexGrow: 1,
    [theme.breakpoints.up('lg')]: {
      width: 'calc(100% - 240px)'
    },
    top: 'auto',
    bottom: 0,
  },
}));



const CompanyClusterSettingKpiList = () => {
  const classes = useStyles();
  const company_cluster_setting_kpi = useSelector(state => state.company_cluster_setting_kpi);
  const dispatch = useDispatch();
  const company_clusterSearchText = null;
  const company_clusterSearchStatuses = [];
 

  // pagination
  const [rowsPerPage, setRowsPerPage] = useState(50);

  const [page, setPage] = useState(0);
  
  const handlePageChange = (event, page) => {
    setPage(page);
  };

  const handleRowsPerPageChange = event => {
    setPage(0);
    setRowsPerPage(event.target.value);
  };

 
  useEffect(() => {
    dispatch(company_clusterActions.getAll(company_clusterSearchStatuses, company_clusterSearchText, '', false, page +1, rowsPerPage));
  }, []);


  // item mapping



  const itemMapper = (item) => {
    return {
      id: item.id,
      action :  "/company-cluster-setting-kpi/" + item.id,
      primary: `${item.name} - ${item.weight}`,
      secondary: `#${item.id} (${item.subcategory_id})`,
    };
  }


  const handleClick = (id) => {
    dispatch(actions.showNew(id));
  }
  return (
    <Grid container spacing={2}>
    <Grid item xs={12}>

      <Card>
        <CardHeader title="Kpi" action={<CompanyClusterSettingKpiToolbar />} ></CardHeader>
        <CardContent>
        <ItemList items={company_cluster_setting_kpi.items ?? []} itemMapper={itemMapper} 
        onClick={handleClick}
         actionPath="/company_cluster_setting_kpi/"/>
      </CardContent>
      </Card>
      </Grid>
    </Grid>
  );
};

export default CompanyClusterSettingKpiList;



