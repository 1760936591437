import React, { useEffect } from 'react';
import { Button, Card, CardActions, CardContent, CardHeader, Grid, Typography } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { CompanyClusterSettingReportInsight } from 'views';

import { actions } from '_model/company_cluster_setting_report_value';
import { actions as filterActions } from '_model/filter';



const CompanyClusterSettingReportValueView = ({ id }) => {

  const item = useSelector(state => state.company_cluster_setting_report_value.item);

  const dispatch = useDispatch();
  
  useEffect(() => {
    if (!id) return;
    dispatch(actions.getOne(id));
    let filter = {};
    filter['company_cluster_setting_report_value_id'] = id;
    dispatch(filterActions.addFilter(filter))
    
  }, [id]);

  const regenerareReport = () => {
    dispatch(actions.createReport(item.settingId, item.companyId));
  }

  if (!item) return null;

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        
        <Card>
          <CardHeader title="Report" 
          action={
            <Button variant="outlined" color="primary"  onClick={regenerareReport}>REGENERATE</Button>
              
          }/>
          <CardContent>
          
            <Typography variant="h3">{item.companyName}</Typography>
            <Typography variant="h5">{item.clusterName}</Typography>
            <Typography variant="h6">{item.settingName}</Typography>
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs={12}>
        <CompanyClusterSettingReportInsight reportValueId={item._id}/>
      </Grid>
    </Grid>
  );
}

export default CompanyClusterSettingReportValueView;

