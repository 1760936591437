import { constants } from './constants';

const initialStatus = {
  successAction: false,
  items: [],
  total: 0,
  item: null,
  loading: false,
  error: null,

};

export function reducer(state = initialStatus, action) {




  switch (action.type) {


    case constants.SHOW_NEW:
      const k = state.items.findIndex(item => {
        console.log(item.id, action.id);
        return item.id === action.id
      });

      if (k < 0) {

        return {
          ...state,
          item: {
            id: null,
            name: '',
          },


        };
      } else {
        return {
          ...state,
          item: state.items[k],


        };
      }
      break;

    case constants.CLOSE_DETAIL:
      return {
        ...state,
        item: null,
      };

    case constants.SAVE_REQUEST:
      return {
        ...state,
        successAction: false,
        doingAction: true,
        actionError: false,
        actionErrorMsg: null,

      };

    case constants.SAVE_SUCCESS:
      return {
        ...state,
        successAction: true,
        doingAction: false,
        actionError: false,
        actionErrorMsg: null,
      };

    case constants.SAVE_FAILURE:
      return {
        ...state,
        successAction: false,
        doingAction: false,
        actionError: true,
        actionErrorMsg: action.error,
      };


    case constants.DELETE_REQUEST:
      return {
        ...state,
        successAction: false,
        doingAction: true,
        actionError: false,
        actionErrorMsg: null,
      };
    case constants.DELETE_SUCCESS:


      return {
        ...state,
        successAction: true,
        doingAction: false,
        actionError: false,
        actionErrorMsg: null,

      };
      break;

    case constants.DELETE_FAILURE:
      return {
        ...state,
        successAction: false,
        doingAction: false,
        actionError: true,
        actionErrorMsg: action.error,
      };


    case constants.GETALL_REQUEST:
      return {
        ...state,
        loading: true
      };
    case constants.GETALL_SUCCESS:
      return {
        ...state,
        items: action.items.data,
        total: action.items.total,
        loading: false,
        successAction: true,
        doingAction: false,
        actionError: false,
        actionErrorMsg: null
      };

    case constants.GETALL_FAILURE:
      return {
        ...state,
        error: action.error,
        loading: false
      };

    case constants.GETONE_REQUEST:
      return {
        ...state,
        loading: true
      };
    case constants.GETONE_SUCCESS:
      return {
        ...state,
        item: action.item,
        loading: false
      };
    case constants.GETONE_FAILURE:
      return {
        ...state,
        error: action.error,
        loading: false
      };


    case constants.CREATE_REPORT_REQUEST:
      return {
        ...state,
        loading: true
      };
    case constants.CREATE_REPORT_SUCCESS:
      return {
        ...state,
        item: action.item,
        loading: false
      };
    case constants.CREATE_REPORT_FAILURE:
      return {
        ...state,
        error: action.error,
        loading: false
      };




    default:
      return state;
  }
}