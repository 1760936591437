import { Box, Button, Card, CardContent, CardHeader, Checkbox, CircularProgress, FormControl, FormControlLabel, Grid, InputLabel, MenuItem, Select } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { DropzoneArea } from 'material-ui-dropzone';
import { useDispatch, useSelector } from 'react-redux';
import { actions } from '_model/import_data';
import { actions as actionsPeriods } from '_model/config_period/actions';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { Alert } from '@material-ui/lab';





const ImportData = () => {

  const headersData = useSelector(state => state.import_data.headers);
  const recordsData = useSelector(state => state.import_data.records);
  const errors = useSelector(state => state.import_data.errors);
  const periods = useSelector(state => state.config_period);

  const [availablePeriods, setAvailablePeriods] = useState([]);

  const [selectedPeriod, setSelectedPeriod] = useState(0);
  const onPeriodChange = (event) => {
    setSelectedPeriod(event.target.value);
  }

  const [forceImport, setForceImport] = useState(false);
  const onForceImport = (event) => {
    setForceImport(event.target.checked);
  }

  useEffect(() => {
    if (periods && periods.items) {
      setAvailablePeriods(periods.items);
    }
  }, [periods]);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(actionsPeriods.getAll());
  }, []);

  useEffect(() => {
    dispatch(actions.getAll);
  }, []);



  const doingAction = useSelector(state => state.import_data.doingAction);
  const successAction = useSelector(state => state.import_data.successAction);
  const actionErrorMsg = useSelector(state => state.import_data.actionErrorMsg);




  const onChange = (files) => {
    if (files.length > 0) {
      dispatch(actions.save(files[0]));
    }
  }

  const onLoadData = () => {
    if (recordsData.length > 0) {
      dispatch(actions.loadData(recordsData, selectedPeriod, forceImport));
    }
  }


  const getCellStyle = (error) => {
    if (error) return {
      border: "1px solid #ddd",
      backgroundColor: "#fdd",
    };

    return {
      border: "1px solid #ddd",
    };

  }

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} >
        <Card>
          <CardHeader title="Import data" />
          <CardContent>
            <Box display="flex" alignContent="center" justifyContent="center" minHeight={200} >
              {doingAction ? (
                <Box m="auto">
                  <CircularProgress></CircularProgress>
                </Box>
              ) : (
                  <DropzoneArea
                    dropzoneText="carica qui dai"
                    acceptedFiles={['text/csv']}
                    filesLimit={1}
                    showPreviewsInDropzone={false}
                    showPreviews={false}

                    onChange={onChange}></DropzoneArea>
                )}
            </Box>
          </CardContent>
        </Card>
      </Grid>
      {actionErrorMsg && (
        <Grid item xs={12} >
          <Alert severity="error">{actionErrorMsg}</Alert>
        </Grid>
      )}
      {errors === true && (
        <Grid item xs={12} >
          <Alert severity="error">Something is missed or there are typo errors it the uploaded file.</Alert>
        </Grid>
      )}
      {(!doingAction && recordsData && recordsData.length > 0) && (
        <>
          <Grid item xs={12} >
            <Card>
              <CardHeader title="Preview" />
              <CardContent>
                <TableContainer component={Paper}>
                  <Table aria-label="simple table">
                    <TableHead>
                      <TableRow>
                        {headersData && headersData.map((field, key) => (
                          <TableCell style={getCellStyle(field.error)} key={key} >
                            {field.value}
                          </TableCell>
                        ))}

                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {recordsData && recordsData.map((row, key) => (
                        <TableRow key={key}>

                          {row && row.map((field, key) => (
                            <TableCell style={getCellStyle(field.error)} key={key} scope="row">
                              {field.value}
                            </TableCell>
                          ))}

                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>

              </CardContent>
            </Card>
          </Grid>


          <Grid item xs={12} >
            <Card>
              <CardHeader title="Load data" />
              <CardContent>
                <FormControl style={{ minWidth: 120 }}>

                  <FormControlLabel
                    control={<Checkbox checked={forceImport} onChange={onForceImport} name="forceImport" />}
                    label="Force import"
                  />
                </FormControl>

                {(forceImport || (!errors && !actionErrorMsg)) && (<>
                <FormControl style={{ minWidth: 120 }}>

                  <Select
                    autoWidth={false}
                    variant="outlined"
                    name="period"
                    value={selectedPeriod}
                    onChange={onPeriodChange}>
                    <MenuItem value="0">Select Period</MenuItem>
                    {availablePeriods.map((period, key) => (
                      <MenuItem key={key} value={period.id}>{period.name}</MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <FormControl>
                  <Button
                    variant="outlined"
                    onClick={onLoadData}
                    size="large"
                  >LOAD DATA</Button>
                </FormControl>
                </>)}
              </CardContent>
            </Card>
          </Grid>
          
        </>


      )}
    </Grid>

  );

};

export default ImportData;
