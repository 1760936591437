import React, { useEffect, useRef } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import validate from 'validate.js';
import { List, FormControl, InputLabel, ListItem, MenuItem, Select, TextField } from '@material-ui/core';
import { actions } from '_model/company_cluster_setting_report_insight';




const defaultFormState = {
  loaded: false,
  isValid: false,
  touched: {},
  errors: {},
  values: {
    category: '',
    rating: 'medio',
    title: '',
    description: '',
  },
};

const schema = {
  category: {
    presence: { allowEmpty: false, message: 'is required' },
    length: {
      maximum: 200
    }
  },
  rating: {
    presence: { allowEmpty: false, message: 'is required' },
    length: {
      maximum: 200
    }
  },
  title: {
    presence: { allowEmpty: false, message: 'is required' },
    length: {
      // maximum: 200
    }
  },
  description: {
    presence: { allowEmpty: false, message: 'is required' },
    length: {
      // maximum: 400
    }
  },


};


const CompanyClusterSettingReportInsightDetail = props => {

  const { company_cluster_setting_report_insight } = props;

  const item = useSelector(state => state.company_cluster_setting_report_insight.item);
 
 

  useEffect(() => {
    if (item !== null) {
      setOpen(true);
    } else {
      setOpen(false);
    }
  }, [item]);

  const [open, setOpen] = useState(false)

  const dispatch = useDispatch();

  const handleChange = (event) => {
    event.persist();
    handleFieldChange(event.target.name, event.target.type === 'checkbox'
      ? event.target.checked
      : event.target.value);
  };


  const handleFieldChange = (fieldName, value) => {
    setFormState(formState => ({
      ...formState,
      values: {
        ...formState.values,
        [fieldName]: value,

      },
      touched: {
        ...formState.touched,
        [fieldName]: true
      }
    }));
  };

  const handleSubmit = event => {
    if (!formState.isValid) return;

  
    if (event.type === 'keypress' && !(event.key == 'Enter' && event.ctrlKey)) {
      return;
    }


    
    event.preventDefault();
    setFormState(formState => ({
      ...formState,
      touched: schema
    }));


    dispatch(actions.save(formState.values));
  };

  const handleDelete = event => {
    event.preventDefault();
    dispatch(actions._delete(formState.values.id));
  };

  const doingAction = useSelector(state => state.company_cluster_setting_report_insight.doingAction);
  const successAction = useSelector(state => state.company_cluster_setting_report_insight.successAction);
  const actionErrorMsg = useSelector(state => state.company_cluster_setting_report_insight.actionErrorMsg);



  useEffect(() => {
    if (successAction === true) handleClose();
  }, [successAction]);

  const handleClose = () => {
    if (!doingAction) {
      dispatch(actions.closeDetail());
      setFormState(defaultFormState);
    }
  };

  const [formState, setFormState] = useState(defaultFormState);

  const formValidation = () => {
    const errors = validate(formState.values, schema);
    setFormState(formState => ({
      ...formState,
      isValid: errors ? false : true,
      errors: errors || {},
    }));
  }

  useEffect(() => {
    formValidation();
  }, [formState.values]);

  useEffect(() => {
    if (item) {
      setFormState({
        ...formState,
        values: {...item},
      });
    } else {
      setFormState(defaultFormState);
    }
  }, [item]);


  useEffect(() => {
    setFormState(formState => ({
      ...formState,
      isValid: actionErrorMsg ? false : true,
      errors: actionErrorMsg ? actionErrorMsg.errors : {},
    }));
  }, [actionErrorMsg]);



  const categories = ['communication','technology','digital-marketing', 'social-media', 'analytics'];

  return (
    <div>

      <Dialog
        fullWidth={true}
        maxWidth="lg"
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">
          {company_cluster_setting_report_insight ? "Report Insight" : "New Report Insight"}
        </DialogTitle>
        <DialogContent>

          <List>

          <ListItem>
              <FormControl variant="outlined" fullWidth={true}>
                <InputLabel >Category</InputLabel>
                <Select
                
                  name="category"
                  value={formState.values.category}
                  onChange={handleChange}
                  label="Category"
                >
                  <MenuItem value="">-</MenuItem>
                  {categories.map((c,k)=> (
                    <MenuItem key={k} value={c} >{c}</MenuItem>

                  ))}
                  
                </Select>
              </FormControl>
            </ListItem>

            <ListItem>
              <FormControl variant="outlined" fullWidth={true}>
                <InputLabel >Rating</InputLabel>
                <Select
                  name="rating"
                  value={formState.values.rating}
                  onChange={handleChange}
                  label="Rating"
                >
                  <MenuItem value="">-</MenuItem>
                  <MenuItem value="basso" style={{ color: "red" }}>BASSO</MenuItem>
                  <MenuItem value="medio" style={{ color: "gray" }}>MEDIO</MenuItem>
                  <MenuItem value="alto" style={{ color: "green" }}>ALTO</MenuItem>
                </Select>
              </FormControl>
            </ListItem>

            <ListItem>
              <TextField
                multiline
                rows={2}
                fullWidth={true}
                autoFocus
                name="title"
                label="Title"
                value={formState.values.title}
                variant="outlined"
                onChange={handleChange}
                onKeyPress={handleSubmit}
              />
            </ListItem>
            <ListItem>

              <TextField
                multiline
                rows={10}
                fullWidth={true}
                name="description"
                label="Description"
                value={formState.values.description}
                variant="outlined"
                onChange={handleChange}
                onKeyPress={handleSubmit}
              />
            </ListItem>
          </List>


        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleSubmit} color="primary">
            Save
          </Button>
          <Button onClick={handleDelete} color="secondary">
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

CompanyClusterSettingReportInsightDetail.defaultProps = {
  company_cluster_setting_report_insight: {},
}
export default CompanyClusterSettingReportInsightDetail;
