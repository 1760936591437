import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { actions,  } from '../../../_model/screenshot/actions';
import {  Card, CardContent, CardHeader, Grid } from '@material-ui/core';
import { ScreenshotTable, ScreenshotToolbar } from '.';

const ScreenshotList = () => {
  
  const screenshot = useSelector(state => state.screenshot);
  const dispatch = useDispatch();
 
  useEffect(() => {
    dispatch(actions.getAll());
  }, []);

  const [rows, setRows] = useState([]);
  const [kpiNames, setKpiNames] = useState([]);

  useEffect(()=> {

    if (!Array.isArray(screenshot.items)) return;

    if (screenshot.items.length === 0) return;
    

    const rr = screenshot.items.map((s)=> {
      const kpivalues = Object.values(s.kpis).map((k)=> k.value);
      return [`${s.period_name}`, `${s.company_name}`, ...kpivalues];
    })

    
    const kn = Object.values(screenshot.items[0].kpis).map((k)=> k.name);
    
    setRows(rr);
    setKpiNames(kn);

  }, [screenshot]);



  return (
    <Grid container spacing={2}>
    <Grid item xs={12}>

      <Card>
        <CardHeader title="Screenshot" action={<ScreenshotToolbar />} ></CardHeader>
        <CardContent>
         <ScreenshotTable screenshots={screenshot} rows={rows} kpiNames={kpiNames}></ScreenshotTable>
      </CardContent>
      </Card>
      </Grid>
    </Grid>
  );
};

export default ScreenshotList;



