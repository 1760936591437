export const constants = {


    SAVE_REQUEST: 'COMPANY_CLUSTER_SETTING_REPORT_VALUE_SAVE_REQUEST',
    SAVE_SUCCESS: 'COMPANY_CLUSTER_SETTING_REPORT_VALUE_SAVE_SUCCESS',
    SAVE_FAILURE: 'COMPANY_CLUSTER_SETTING_REPORT_VALUE_SAVE_FAILURE',
   
    GETALL_REQUEST: 'COMPANY_CLUSTER_SETTING_REPORT_VALUE_GETALL_REQUEST',
    GETALL_SUCCESS: 'COMPANY_CLUSTER_SETTING_REPORT_VALUE_GETALL_SUCCESS',
    GETALL_FAILURE: 'COMPANY_CLUSTER_SETTING_REPORT_VALUE_GETALL_FAILURE',

    GETONE_REQUEST: 'COMPANY_CLUSTER_SETTING_REPORT_VALUE_GETONE_REQUEST',
    GETONE_SUCCESS: 'COMPANY_CLUSTER_SETTING_REPORT_VALUE_GETONE_SUCCESS',
    GETONE_FAILURE: 'COMPANY_CLUSTER_SETTING_REPORT_VALUE_GETONE_FAILURE',

    DELETE_REQUEST: 'COMPANY_CLUSTER_SETTING_REPORT_VALUE_DELETE_REQUEST',
    DELETE_SUCCESS: 'COMPANY_CLUSTER_SETTING_REPORT_VALUE_DELETE_SUCCESS',
    DELETE_FAILURE: 'COMPANY_CLUSTER_SETTING_REPORT_VALUE_DELETE_FAILURE',

    CREATE_REPORT_REQUEST: 'COMPANY_CLUSTER_SETTING_REPORT_VALUE_CREATE_REPORT_REQUEST',
    CREATE_REPORT_SUCCESS: 'COMPANY_CLUSTER_SETTING_REPORT_VALUE_CREATE_REPORT_SUCCESS',
    CREATE_REPORT_FAILURE: 'COMPANY_CLUSTER_SETTING_REPORT_VALUE_CREATE_REPORT_FAILURE',

    SHOW_NEW : 'COMPANY_CLUSTER_SETTING_REPORT_VALUE_SHOW_NEW',
    CLOSE_DETAIL : 'COMPANY_CLUSTER_SETTING_REPORT_VALUE_CLOSE_DETAIL',
};
