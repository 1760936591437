export const constants = {

    GETALL_REQUEST: 'USER_GETALL_REQUEST',
    GETALL_SUCCESS: 'USER_GETALL_SUCCESS',
    GETALL_FAILURE: 'USER_GETALL_FAILURE',

    GETONE_REQUEST: 'USER_GETONE_REQUEST',
    GETONE_SUCCESS: 'USER_GETONE_SUCCESS',
    GETONE_FAILURE: 'USER_GETONE_FAILURE',

    DELETE_REQUEST: 'USER_DELETE_REQUEST',
    DELETE_SUCCESS: 'USER_DELETE_SUCCESS',
    DELETE_FAILURE: 'USER_DELETE_FAILURE',
};
