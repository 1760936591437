export const constants = {

    SAVE_REQUEST: 'COMPANY_CLUSTER_SETTING_KPI_SAVE_REQUEST',
    SAVE_SUCCESS: 'COMPANY_CLUSTER_SETTING_KPI_SAVE_SUCCESS',
    SAVE_FAILURE: 'COMPANY_CLUSTER_SETTING_KPI_SAVE_FAILURE',
   
    GETALL_REQUEST: 'COMPANY_CLUSTER_SETTING_KPI_GETALL_REQUEST',
    GETALL_SUCCESS: 'COMPANY_CLUSTER_SETTING_KPI_GETALL_SUCCESS',
    GETALL_FAILURE: 'COMPANY_CLUSTER_SETTING_KPI_GETALL_FAILURE',

    GETONE_REQUEST: 'COMPANY_CLUSTER_SETTING_KPI_GETONE_REQUEST',
    GETONE_SUCCESS: 'COMPANY_CLUSTER_SETTING_KPI_GETONE_SUCCESS',
    GETONE_FAILURE: 'COMPANY_CLUSTER_SETTING_KPI_GETONE_FAILURE',

    DELETE_REQUEST: 'COMPANY_CLUSTER_SETTING_KPI_DELETE_REQUEST',
    DELETE_SUCCESS: 'COMPANY_CLUSTER_SETTING_KPI_DELETE_SUCCESS',
    DELETE_FAILURE: 'COMPANY_CLUSTER_SETTING_KPI_DELETE_FAILURE',

    SHOW_NEW : 'COMPANY_CLUSTER_SETTING_KPI_SHOW_NEW',
    CLOSE_DETAIL : 'COMPANY_CLUSTER_SETTING_KPI_CLOSE_DETAIL',
};
