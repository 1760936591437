import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/styles';
import { useDispatch, useSelector } from 'react-redux';
import { actions } from '../../../_model/report_user/actions';
import { Card, CardContent, CardHeader, Grid } from '@material-ui/core';
import ItemList from 'common/ItemList';
import Toolbar from 'common/Toolbar';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(0),
    backgroundColor: "white",
  },
  content: {
    marginTop: theme.spacing(0)
  },
  footerAppBar: {
    flexGrow: 1,
    [theme.breakpoints.up('lg')]: {
      width: 'calc(100% - 240px)'
    },
    top: 'auto',
    bottom: 0,
  },
}));



const ReportUserList = () => {
  
  const report_user = useSelector(state => state.report_user);
  const dispatch = useDispatch();
  const companySearchText = null;
  const companySearchStatuses = [];


  // pagination
  const [rowsPerPage, setRowsPerPage] = useState(50);

  const [page, setPage] = useState(0);

  const handlePageChange = (event, page) => {
    setPage(page);
  };

  const handleRowsPerPageChange = event => {
    setPage(0);
    setRowsPerPage(event.target.value);
  };


  useEffect(() => {
    dispatch(actions.getAll(companySearchStatuses, companySearchText, '', false, page + 1, rowsPerPage));
  }, []);


  // item mapping



  const itemMapper = (item) => {

    const demoLabel = item.demo ? 'DEMO MODE' : '';
    return {
      id: item.id,
      //action: "/report-user/" + item.id,
      action: null,
      primary: <span>{`${item.name} - ${item.email}`} <span style={{color:"red"}}>{demoLabel}</span></span>,
      secondary: item.title,
    };
  }



  const handleClick = (id) => {
    dispatch(actions.showNew(id));
  }
  return (
    <Grid container spacing={4}>
      <Grid item xs={12}>

        <Card>

          <CardHeader title="Report Users" action={<Toolbar actions={actions} />} ></CardHeader>
          <CardContent>
            <ItemList items={report_user.items ?? []} itemMapper={itemMapper}
              onClick={handleClick}
              actionPath="/company/" />
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
};

export default ReportUserList;



