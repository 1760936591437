import { authHeader } from '../../helpers';
import handleResponse from '../handleResponse';


const endpoint = process.env.REACT_APP_API_ENDPOINT + `/api/import-data/`;
export const service = {
    save,
    getAll,
    getById,
    _delete,
    loadData,
};

function _delete(id) {
    const requestOptions = {
        method: 'DELETE',
        headers: authHeader()
    };

    return fetch(endpoint + id, requestOptions).then(handleResponse);
}



function save(data) {

    const formData = new FormData();
    formData.append('importDataFile', data);
    
    const requestOptions = {
        method: 'POST',
        headers: authHeader(false),
        body: formData,
        redirect: 'follow',
    };


    return fetch(endpoint, requestOptions).then(handleResponse);

}


function loadData(data, selectedPeriod, forceImport) {

    const method = 'PUT';

    const body = {
        data: data, 
        selectedPeriod: selectedPeriod,
        forceImport: forceImport,
    };
    
    const requestOptions = {
        method: method,
        headers: authHeader(),
        body: JSON.stringify(body)
    };

    return fetch(endpoint, requestOptions).then(handleResponse);
}


function getAll(params={}, page = '', per_page = '') {
    
    const url = new URL(endpoint);
    url.search = new URLSearchParams({...params, page, per_page}).toString();
    
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    return fetch(url, requestOptions).then(handleResponse);

}

function getById(id) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    return fetch(endpoint + id, requestOptions).then(handleResponse);
}
