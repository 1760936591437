import React, { useEffect, useRef } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import validate from 'validate.js';
import { FormControl, FormControlLabel, InputLabel, List, ListItem, MenuItem, Select, Switch, TextField } from '@material-ui/core';
import { actions } from '_model/report_user';
import { actions as actionsReportValue } from '../../../_model/company_cluster_setting_report_value/actions';





const defaultFormState = {
  loaded: false,
  isValid: false,
  touched: {},
  errors: {},
  values: {
    name: '',
  },
};

const schema = {
  name: {
    presence: { allowEmpty: false, message: 'is required' },
    length: {
      maximum: 200
    }
  },


};


const ReportUserDetail = props => {

  const { report_user } = props;


  const item = useSelector(state => state.report_user.item);

  useEffect(() => {
    if (item !== null) {
      setOpen(true);
    } else {
      setOpen(false);
    }
  }, [item]);

  const [open, setOpen] = useState(false)

  const dispatch = useDispatch();

  const handleChange = (event) => {
    console.log(event);
    event.persist();
    handleFieldChange(event.target.name, event.target.type === 'checkbox'
      ? event.target.checked
      : event.target.value);
  };


  const handleFieldChange = (fieldName, value) => {
    setFormState(formState => ({
      ...formState,
      values: {
        ...formState.values,
        [fieldName]: value,

      },
      touched: {
        ...formState.touched,
        [fieldName]: true
      }
    }));
  };

  const handleSubmit = event => {
    if (!formState.isValid) return;

    if (event.type === 'keypress' && event.key !== 'Enter') {
      return;
    }
    event.preventDefault();
    setFormState(formState => ({
      ...formState,
      touched: schema
    }));



    dispatch(actions.save(formState.values));
  };

  const handleDelete = event => {
    event.preventDefault();
    dispatch(actions._delete(formState.values.id));
  };

  const doingAction = useSelector(state => state.report_user.doingAction);
  const successAction = useSelector(state => state.report_user.successAction);
  const actionErrorMsg = useSelector(state => state.report_user.actionErrorMsg);



  useEffect(() => {
    console.log("SUCCESS ACTION", successAction);
    if (successAction === true) handleClose();
  }, [successAction]);

  const handleClose = () => {
    if (!doingAction) {
      dispatch(actions.closeDetail());
      setFormState(defaultFormState);
    }
  };

  const [formState, setFormState] = useState(defaultFormState);

  const formValidation = () => {
    const errors = validate(formState.values, schema);
    setFormState(formState => ({
      ...formState,
      isValid: errors ? false : true,
      errors: errors || {},
    }));
  }

  useEffect(() => {
    formValidation();
  }, [formState.values]);

  useEffect(() => {

    console.log(item);
    if (item) {
      setFormState({
        ...formState,
        values: { ...item },
      });
    } else {
      setFormState(defaultFormState);
    }
  }, [item]);


  useEffect(() => {
    setFormState(formState => ({
      ...formState,
      isValid: actionErrorMsg ? false : true,
      errors: actionErrorMsg ? actionErrorMsg.errors : {},
    }));
  }, [actionErrorMsg]);



  const company_cluster_setting_report_value = useSelector(state => state.company_cluster_setting_report_value);

  useEffect(() => {
    dispatch(actionsReportValue.getAll({ agency_id: null }, 1, 1000));
  }, []);

  return (
    <div>

      <Dialog
        fullWidth={true}
        maxWidth="sm"
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">
          {report_user ? "User detail" : "New User"}
        </DialogTitle>
        <DialogContent>

          <List>

            <ListItem>
              <TextField
                autoFocus
                name="name"
                label="Name"
                value={formState.values.name}
                InputProps={{
                  // readOnly: true,
                }}
                fullWidth={true}
                variant="outlined"
                onChange={handleChange}
                onKeyPress={handleSubmit}
              />
            </ListItem>
            <ListItem>

              <TextField
                name="title"
                label="Title"
                value={formState.values.title}
                InputProps={{
                  // readOnly: true,
                }}
                fullWidth={true}
                variant="outlined"
                onChange={handleChange}
                onKeyPress={handleSubmit}
              />
            </ListItem>
            <ListItem>

              <TextField
                name="email"
                label="Email"
                value={formState.values.email}
                InputProps={{
                  // readOnly: true,
                }}
                fullWidth={true}
                variant="outlined"
                onChange={handleChange}
                onKeyPress={handleSubmit}
              />
            </ListItem>
            <ListItem>

              <TextField
                name="password"
                label="Password"
                value={formState.values.password}
                InputProps={{
                  // readOnly: true,
                }}
                fullWidth={true}
                variant="outlined"
                onChange={handleChange}
                onKeyPress={handleSubmit}
              />
            </ListItem>
            <ListItem>
              <FormControl fullWidth={true} variant="outlined" >
                <InputLabel >Report</InputLabel>
                <Select
                  fullWidth={true}
                  // labelId="demo-simple-select-outlined-label"
                  // id="demo-simple-select-outlined"
                  value={formState.values.report_id}
                  onChange={handleChange}
                  label="Report Enabled"
                  name="report_id"
                >
                  <MenuItem value="">---</MenuItem>
                  {company_cluster_setting_report_value.items.map((r, key) => (
                    <MenuItem key={key} value={r._id}>{r.clusterName} {r.companyName} {r.periodName}</MenuItem>
                  ))}
                </Select>
              </FormControl>

            </ListItem>
            <ListItem>
              <FormControlLabel
                control={<Switch checked={formState.values.demo} onChange={handleChange} name="demo" />}
                label="Activate Demo Mode"
              />

            </ListItem>
          </List>

        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleSubmit} color="primary">
            Save
          </Button>
          <Button onClick={handleDelete} color="secondary">
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

ReportUserDetail.defaultProps = {
  report_user: {},
}
export default ReportUserDetail;
