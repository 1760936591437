import React from 'react';
import { useParams } from 'react-router';
import ReportUserList, { ReportUserDetail, ReportUserView } from './components';


const ReportUser = () => {

  const { report_user_id } = useParams();
  return (
    <>
      {report_user_id ? ( <ReportUserView id={report_user_id} />) : (
          <>
            <ReportUserList />
            <ReportUserDetail />
          </>
        )}
    </>
  );
};

export default ReportUser;
