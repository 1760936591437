import React, { useEffect, useRef } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import validate from 'validate.js';
import { Box, FormControl, InputLabel, MenuItem, Select, TextField } from '@material-ui/core';
import { actions } from '_model/company_cluster_setting';



const defaultFormState = {
  loaded: false,
  isValid: false,
  touched: {},
  errors: {},
  values: {
    name: '',
  },
};

const schema = {
  name: {
    presence: { allowEmpty: false, message: 'is required' },
    length: {
      maximum: 200
    }
  },


};


const CompanyClusterSettingDetail = props => {

  const { company_cluster_setting } = props;


  const item = useSelector(state => state.company_cluster_setting.item);
  const items = useSelector(state => state.company_cluster_setting.items);
  useEffect(() => {
    dispatch(actions.getAll({}));
  }, []);

  useEffect(() => {
    if (item !== null) {
      setOpen(true);
    } else {
      setOpen(false);
    }
  }, [item]);

  const [open, setOpen] = useState(false)

  const dispatch = useDispatch();

  const handleChange = (event) => {
    event.persist();
    handleFieldChange(event.target.name, event.target.type === 'checkbox'
      ? event.target.checked
      : event.target.value);
  };


  const handleFieldChange = (fieldName, value) => {
    setFormState(formState => ({
      ...formState,
      values: {
        ...formState.values,
        [fieldName]: value,

      },
      touched: {
        ...formState.touched,
        [fieldName]: true
      }
    }));
  };

  const handleSubmit = event => {
    if (!formState.isValid) return;

    if (event.type === 'keypress' && event.key !== 'Enter') {
      return;
    }
    event.preventDefault();
    setFormState(formState => ({
      ...formState,
      touched: schema
    }));


    dispatch(actions.save(formState.values));
  };

  const handleDelete = event => {
    event.preventDefault();
    dispatch(actions._delete(formState.values.id));
  };

  const doingAction = useSelector(state => state.company_cluster_setting.doingAction);
  const successAction = useSelector(state => state.company_cluster_setting.successAction);
  const actionErrorMsg = useSelector(state => state.company_cluster_setting.actionErrorMsg);


  useEffect(() => {
    if (successAction === true) handleClose();
  }, [successAction]);

  const handleClose = () => {
    if (!doingAction) {
      dispatch(actions.closeDetail());
      setFormState(defaultFormState);
    }
  };

  const [formState, setFormState] = useState(defaultFormState);

  const formValidation = () => {
    const errors = validate(formState.values, schema);
    setFormState(formState => ({
      ...formState,
      isValid: errors ? false : true,
      errors: errors || {},
    }));
  }

  useEffect(() => {
    formValidation();
  }, [formState.values]);

  useEffect(() => {
    if (item) {
      setFormState({
        ...formState,
        values: { ...item },
      });
    } else {
      setFormState(defaultFormState);
    }
  }, [item]);


  useEffect(() => {
    setFormState(formState => ({
      ...formState,
      isValid: actionErrorMsg ? false : true,
      errors: actionErrorMsg ? actionErrorMsg.errors : {},
    }));
  }, [actionErrorMsg]);



  return (
    <div>

      <Dialog
        fullWidth={true}
        maxWidth="md"
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title">

        <DialogTitle id="form-dialog-title">Cluster Setting {formState.values.id}</DialogTitle>
        <DialogContent>

        <FormControl  style={{minWidth: "200px"}} >
          <TextField
            autoFocus
            // id="name"
            name="name"
            label="CompanyClusterSetting Name"
            value={formState.values.name}
            InputProps={{
              // readOnly: true,
            }}
            variant="outlined"
            onChange={handleChange}
            onKeyPress={handleSubmit}
          />
          </FormControl>
          
          <FormControl style={{minWidth: "200px"}} >
            <InputLabel id="demo-simple-select-label">Parser</InputLabel>
            <Select
              autoWidth={true}
              variant="outlined"
              name="parserName"
              value={formState.values.parserName}
              onChange={handleChange}
            >
              <MenuItem value="base">Base</MenuItem>
              <MenuItem value="fashion">Fashion</MenuItem>
              <MenuItem value="pharma">Pharma</MenuItem>
              <MenuItem value="gdo">Gdo</MenuItem>
              <MenuItem value="computer">Computer</MenuItem>
              

            </Select>
          </FormControl>

          <FormControl style={{minWidth: "200px"}} >
            <InputLabel id="demo-simple-select-label">Clone from</InputLabel>
            <Select
              autoWidth={true}
              variant="outlined"
              name="parserCloneId"
              value={formState.values.parserCloneId}
              onChange={handleChange}
            >
              { items && items.map((i, key)=>(
              <MenuItem key={key} value={i.id}>{i.name}</MenuItem>
              ))}
              

            </Select>
          </FormControl>
          
        </DialogContent>

        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleSubmit} color="primary">
            Save
          </Button>
          <Button onClick={handleDelete} color="secondary">
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

CompanyClusterSettingDetail.defaultProps = {
  company_cluster_setting: {},
}
export default CompanyClusterSettingDetail;
