import React, { useState } from 'react';
import { useEffect } from 'react';
import { useParams } from 'react-router';
import CompanyClusterSettingReportValueList, { CompanyClusterSettingReportValueView } from './components';

const CompanyClusterSettingReportValue = ({report_id}) => {

  const { company_cluster_setting_report_value_id } = useParams();

  const [id, setId] = useState();

  useEffect(()=> {
    console.log("REP", company_cluster_setting_report_value_id);
    setId(company_cluster_setting_report_value_id);
  }, [company_cluster_setting_report_value_id]);

 

  return (
    <>
    {company_cluster_setting_report_value_id ? ( 
      <CompanyClusterSettingReportValueView id={id} />
    ) : (
      <CompanyClusterSettingReportValueList />
    )}
      
    </>
  );

};

export default CompanyClusterSettingReportValue;
