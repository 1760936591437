import { constants } from './constants';

const user = localStorage.getItem('user');
const userData = user ? JSON.parse(user) : {};

const initialState = user ? {
  loggedIn: true,
  user: { ...userData },
  loading: false,
} :
  {
    loggedIn: false,
    user: null,
    loading: false,
  };

export function reducer(state = initialState, action) {
  switch (action.type) {
    case constants.LOGIN_REQUEST:
      return {
        loading: true,
        ...state,
      };
    case constants.LOGIN_SUCCESS:
      return {
        loggedIn: true,
        user: action.user,
        loading: false,
      };
    case constants.LOGIN_FAILURE:
      return {
        loggedIn: false,
        user: null,
        loading: false,
      };

    case constants.LOGOUT_REQUEST:
      return {
        loading: true,
        ...state,
      };
    case constants.LOGOUT_SUCCESS:
      return {
        loggedIn: false,
        user: null,
        loading: false,
      };
    case constants.LOGOUT_FAILURE:
      return {
        loggedIn: false,
        user: null,
        loading: false,
      };

    default:
      return state
  }
}