import { authHeader } from '../../helpers';
import handleResponse from '../handleResponse';


export const service = {
    login,
    logout,
};

function login(username, password) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify({
            'username' : username,
            'password': password
        })
    };


    return fetch(process.env.REACT_APP_API_ENDPOINT + `/api/login/`, requestOptions).then(handleResponse);
}

function logout() {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    return fetch(process.env.REACT_APP_API_ENDPOINT + `/api/login/`, requestOptions).then(handleResponse);
}


