export const constants = {

    LOGIN_REQUEST: 'AUTH_LOGIN_REQUEST',
    LOGIN_SUCCESS: 'AUTH_LOGIN_SUCCESS',
    LOGIN_FAILURE: 'AUTH_LOGIN_FAILURE',

    LOGOUT_REQUEST: 'AUTH_LOGOUT_REQUEST',
    LOGOUT_SUCCESS: 'AUTH_LOGOUT_SUCCESS',
    LOGOUT_FAILURE: 'AUTH_LOGOUT_FAILURE',
    
};
