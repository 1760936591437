import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/styles';
import { useDispatch, useSelector } from 'react-redux';
import { actions, actions as projectActions } from '../../../_model/project/actions';
import { AppBar, TablePagination, Toolbar } from '@material-ui/core';
import ItemList from 'common/ItemList';
// import { configActions } from '_actions/config.actions';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(0),
    backgroundColor: "white",
  },
  content: {
    marginTop: theme.spacing(0)
  },
  footerAppBar: {
    flexGrow: 1,
    [theme.breakpoints.up('lg')]: {
      width: 'calc(100% - 240px)'
    },
    top: 'auto',
    bottom: 0,
  },
}));



const ProjectList = () => {
  const classes = useStyles();
  const project = useSelector(state => state.project);
  const dispatch = useDispatch();
  const projectSearchText = null;
  const projectSearchStatuses = [];
 

  // pagination
  const [rowsPerPage, setRowsPerPage] = useState(50);

  const [page, setPage] = useState(0);
  
  const handlePageChange = (event, page) => {
    setPage(page);
  };

  const handleRowsPerPageChange = event => {
    setPage(0);
    setRowsPerPage(event.target.value);
  };

 
  useEffect(() => {
    dispatch(projectActions.getAll(projectSearchStatuses, projectSearchText, '', false, page +1, rowsPerPage));
  }, []);


  // item mapping

  const projectMapper = (project) => {
    return {
      id: project.id,
      action : null, // "/companies/" + project.id,
      primary: project.name,
      secondary: project.website,
    };
  }

  const handleClick = (id) => {
    dispatch(actions.showNew(id));
  }
  return (
    <div className={classes.root}>

      <div className={classes.content}>
        <ItemList items={project.items ?? []} itemMapper={projectMapper} 
        onClick={handleClick}
         actionPath="/project/"/>
      </div>


      <AppBar position="fixed"   className={classes.footerAppBar}>
        <Toolbar disableGutters={true} style={{ minHeight: "auto", backgroundColor: "#f5f5f5", color: "black", display: 'flex', justifyContent: 'flex-end' }}>
        { (project.items && project.items.length > 0) && (
        <TablePagination
            component="div"
            count={project.total}
            onChangePage={handlePageChange}
            onChangeRowsPerPage={handleRowsPerPageChange}
            page={page}
            rowsPerPage={rowsPerPage}
            rowsPerPageOptions={[5, 10, 25, 50, 100, 150, 200]}
          />
          )}
          </Toolbar>
        </AppBar>
    </div>
  );
};

export default ProjectList;



