import { Card, CardContent, CardHeader, Grid, Typography } from '@material-ui/core';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { actions } from '_model/report_user';
import { actions as filterActions } from '_model/filter';


const ReportUserView = ({ id }) => {

  const item = useSelector(state => state.report_user.item);

  const dispatch = useDispatch();
  
  useEffect(() => {
    if (!id) return;dispatch(actions.getOne(id));
    let filter = {};
    filter['company_id'] = id;
    dispatch(filterActions.addFilter(filter))

    return () => {
      dispatch(filterActions.removeFilter('company_id'));
    };
  }, [id]);

  if (!item) return null;

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Card>
          <CardHeader title="ReportUser" />
          <CardContent>
            <Typography>{item.name}</Typography>
          </CardContent>
        </Card>
      </Grid>
      
    </Grid>
  );
}

export default ReportUserView;
