import { Card, CardContent, CardHeader, Grid, Typography } from '@material-ui/core';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { actions } from '_model/config_kpi';
import { actions as filterActions } from '_model/filter';

const ConfigKpiView = ({ id }) => {

  const item = useSelector(state => state.config_kpi.item);
  const dispatch = useDispatch();
  useEffect(() => {
    if (!id) return;dispatch(actions.getOne(id));
    let filter = {};
    filter['kpi_id'] = id;
    dispatch(filterActions.addFilter(filter))
  }, [id]);

  if (!item) return null;

  return (

    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Card>
          <CardHeader title="Kpi" />
          <CardContent>
          <Typography>#{item.id} {item.name} - {item.weight}</Typography>
          </CardContent>
        </Card>
      </Grid>

      <Grid item xs={12}>
      
      </Grid>
    </Grid>


  );
}


export default ConfigKpiView;
