import React from 'react';
import { useParams } from 'react-router';
import CompanyClusterSettingList, { CompanyClusterSettingDetail, CompanyClusterSettingView } from './components';

const CompanyClusterSetting = ({clusterId}) => {

  const { company_cluster_setting_id } = useParams();

  return (
    <>
    {company_cluster_setting_id ? ( 
      <CompanyClusterSettingView id={company_cluster_setting_id} />
    ) : (
      <>
      <CompanyClusterSettingList clusterId={clusterId}/>
      <CompanyClusterSettingDetail />
      </>
    )}
      
    </>
  );

};

export default CompanyClusterSetting;
