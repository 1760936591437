import { constants } from './constants';

const initialStatus = {
  successAction: false,
  items: [],
  total: 0,
  item: null,
  loading: false,
  error: null,

};

export function reducer(state = initialStatus, action) {


  switch (action.type) {


    case constants.GETALL_REQUEST:
      return {
        ...state,
        loading: true
      };
    case constants.GETALL_SUCCESS:
      return {
        ...state,
        items: action.items,
        total: action.meta.total,
        loading: false
      };
    case constants.GETALL_FAILURE:
      return {
        ...state,
        error: action.error,
        loading: false
      };

    case constants.GETONE_REQUEST:
      return {
        ...state,
        loading: true
      };
    case constants.GETONE_SUCCESS:
      return {
        ...state,
        item: action.item,
        loading: false
      };
    case constants.GETONE_FAILURE:
      return {
        ...state,
        error: action.error,
        loading: false
      };



   

    default:
      return state;
  }
}