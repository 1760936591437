export const constants = {
    
    SAVE_REQUEST: 'IMPORT_DATA_SAVE_REQUEST',
    SAVE_SUCCESS: 'IMPORT_DATA_SAVE_SUCCESS',
    SAVE_FAILURE: 'IMPORT_DATA_SAVE_FAILURE',
   
    GETALL_REQUEST: 'IMPORT_DATA_GETALL_REQUEST',
    GETALL_SUCCESS: 'IMPORT_DATA_GETALL_SUCCESS',
    GETALL_FAILURE: 'IMPORT_DATA_GETALL_FAILURE',

    GETONE_REQUEST: 'IMPORT_DATA_GETONE_REQUEST',
    GETONE_SUCCESS: 'IMPORT_DATA_GETONE_SUCCESS',
    GETONE_FAILURE: 'IMPORT_DATA_GETONE_FAILURE',

    DELETE_REQUEST: 'IMPORT_DATA_DELETE_REQUEST',
    DELETE_SUCCESS: 'IMPORT_DATA_DELETE_SUCCESS',
    DELETE_FAILURE: 'IMPORT_DATA_DELETE_FAILURE',

    LOAD_REQUEST: 'IMPORT_DATA_LOAD_REQUEST',
    LOAD_SUCCESS: 'IMPORT_DATA_LOAD_SUCCESS',
    LOAD_FAILURE: 'IMPORT_DATA_LOAD_FAILURE',
    
};
