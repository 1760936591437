import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { Box } from '@material-ui/core';



const useStyles = makeStyles(theme => ({
  root: {
    boxShadow: 'none',
    backgroundColor: 'gray',
  },
  flexGrow: {
    flexGrow: 1
  },
  signOutButton: {
    marginLeft: theme.spacing(1)
  },
  fabProgress: {
    color: "#FFFFFF",
  }
}));

const UtilityBar = props => {
  const { className} = props;

  const classes = useStyles();

  
  return (
    <Box className={classes.root}>pinco pallo</Box>
  );
};

UtilityBar.propTypes = {
  className: PropTypes.string,
};

export default UtilityBar;
