import { Card, CardContent, CardHeader, Grid, Typography } from '@material-ui/core';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import CompanyCluster from 'views/CompanyCluster';
import { actions as filterActions } from '_model/filter';


const AgencyView = ({ id, title,actions,  filter_param }) => {

  const item = useSelector(state => state.agency.item);

  const dispatch = useDispatch();
  useEffect(() => {
    if (!id) return;dispatch(actions.getOne(id));
    let filter = {};
    filter[filter_param] = id;
    dispatch(filterActions.addFilter(filter))
  }, [id]);

  if (!item) return null;

  return (

    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Card>
          <CardHeader title={title} />
          <CardContent>
          <Typography>{item.id} - {item.name}</Typography>
          </CardContent>
        </Card>
      </Grid>

      <Grid item xs={12}>
        <CompanyCluster/>
      </Grid>
    </Grid>


  );
}


export default AgencyView;
