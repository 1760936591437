import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { Divider, Drawer, Box, Typography } from '@material-ui/core';
import DashboardIcon from '@material-ui/icons/Dashboard';
import PeopleIcon from '@material-ui/icons/People';
import MessageModelIcon from '@material-ui/icons/EmailTwoTone';
import SettingsIcon from '@material-ui/icons/Settings';
import MessageIcon from '@material-ui/icons/Email';
import CompanyIcon from '@material-ui/icons/Business';
import PeriodIcon from '@material-ui/icons/CalendarToday';
import ImportDataIcon from '@material-ui/icons/CloudUpload';
import { SidebarNav } from './components';
import { useLocation } from 'react-router';
import InputIcon from '@material-ui/icons/Input';
import { CenterFocusWeak, FolderOpen, SpeakerGroup } from '@material-ui/icons';


const useStyles = makeStyles(theme => ({
  drawer: {
    width: 240,
    
    [theme.breakpoints.up('lg')]: {
      marginTop: 0,
      height: 'calc(100%)'
    }
  },
  root: {
    backgroundColor: theme.palette.white,
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    padding: theme.spacing(2)
  },
  divider: {
    margin: theme.spacing(2, 0)
  },
  nav: {
    marginBottom: theme.spacing(2)
  },
  currentPageName: {
    paddingLeft: theme.spacing(2)
  },
}));


const pagesUser = [
  {
    title: 'Dashboard',
    href: '/dashboard',
    icon: <DashboardIcon />
  },
  {
    title: 'Clusters',
    href: '/company-cluster',
    icon: <MessageIcon />
  },
  {
    title: 'Cluster Settings',
    href: '/company-cluster-setting',
    icon: <MessageIcon />
  },
  {
    title: 'Reports',
    href: '/company-cluster-setting-report-value',
    icon: <MessageIcon />
  },
  {
    title: 'Report Users',
    href: '/report-user',
    icon: <PeopleIcon />
  },
  
];



const pagesConfig = [
  {
    title: 'Kpi',
    href: '/config-kpi',
    icon: <CenterFocusWeak />
  },
  {
    title: 'Companies',
    href: '/companies',
    icon: <CompanyIcon />
  },
  {
    title: 'Period',
    href: '/config-period',
    icon: <PeriodIcon />
  },
  {
    title: 'Import Data',
    href: '/import-data',
    icon: <ImportDataIcon />
  },
  {
    title: 'Source Values',
    href: '/source-value',
    icon: <ImportDataIcon />
  },
  

];

const pagesAdmin = [
  {
    title: 'Agencies',
    href: '/agencies',
    icon: <MessageIcon />
  },
  {
    title: 'Users',
    href: '/users',
    icon: <PeopleIcon />
  },
];

const pagesTools = [
  {
    title: 'Settings',
    href: '/settings',
    icon: <SettingsIcon />
  },
  {
    title: 'Logout',
    href: '/sign-out',
    icon: <InputIcon />
  }

];





const Sidebar = props => {
  const { open, variant, onClose, onClick, className, ...rest } = props;

  const classes = useStyles();

  const location = useLocation();

 


  return (
    <Drawer
      anchor="left"
      classes={{ paper: classes.drawer }}
      onClose={onClose}
      open={open}
      variant={variant}
    >
      <div
        {...rest}
        className={clsx(classes.root, className)}
      >
        <Box mx="auto" mb="5px" mt="-8px">
          <img src="https://www.quodigi.com/wp-content/uploads/quodigip2-1.svg" width="110" ></img>
        </Box>
        <Divider />        

        <SidebarNav
          onClick={onClick}
          className={classes.nav}
          pages={pagesUser}
        />
        <Divider /> 
        <SidebarNav
          onClick={onClick}
          className={classes.nav}
          pages={pagesConfig}
        />
        <Divider />          
        <SidebarNav
          onClick={onClick}
          className={classes.nav}
          pages={pagesAdmin}
        />
        <Divider />          
        <SidebarNav
          onClick={onClick}
          className={classes.nav}
          pages={pagesTools}
        />


      </div>
    </Drawer>
  );
};

Sidebar.propTypes = {
  className: PropTypes.string,
  onClose: PropTypes.func,
  open: PropTypes.bool.isRequired,
  variant: PropTypes.string.isRequired
};

const CurrentPageName = props => {


  const classes = useStyles();

  const location = useLocation();
  
  const page =  pagesAdmin.concat(pagesUser).concat(pagesConfig).find(function(element) {
    return element.href === location.pathname;
  });

  
 return (
 <Typography variant="h5" color="inherit" className={classes.currentPageName}>
   {page && page.title}
   
 </Typography>);
}

export { Sidebar as default, CurrentPageName};
