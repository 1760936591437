import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { actions } from '../../../_model/company_cluster_setting_report_insight/actions';
import { Card, CardContent, CardHeader, Grid, Typography } from '@material-ui/core';
import ItemList from 'common/ItemList';
import Toolbar from 'common/Toolbar';






const CompanyClusterSettingReportInsight = ({ reportValueId }) => {

  

  

  const loading = useSelector(state => state.company_cluster_setting_report_insight.loading);
  const company_cluster_setting_report_insight = useSelector(state => state.company_cluster_setting_report_insight);

  const [show, setShow] = useState(false);
  useEffect(()=> {
    if (!loading) setShow(true);
    
  }, [ company_cluster_setting_report_insight.items]);

  useEffect(() => {
    setShow(false);
    return ()=> {
      setShow(false);
    };
  }, []);
  
  
  
  const dispatch = useDispatch();

  const [insights, setInsights] = useState([]);

  useEffect(() => {
    let data = [];
    company_cluster_setting_report_insight.items.forEach(item => {
      if (!data[item.category]) {
        
        data[item.category] = {
          items: [],
          category: item.category
        };
      }
      data[item.category].items.push(item);
    });
    setInsights(Object.values(data));
  }, [company_cluster_setting_report_insight]);

  // pagination
  const [rowsPerPage, setRowsPerPage] = useState(50);

  const [page, setPage] = useState(0);

  const handlePageChange = (event, page) => {
    setPage(page);
  };

  const handleRowsPerPageChange = event => {
    setPage(0);
    setRowsPerPage(event.target.value);
  };


  useEffect(() => {
    setShow(false);
    dispatch(actions.getAll( {company_cluster_setting_report_value_id: reportValueId}, 1, 1000));
    
  }, [reportValueId]);


  // item mapping
  const itemMapper = (item) => {
    return {
      id: item.id,
      //action: "/company-cluster-setting-report-insight/" + item._id,
      primary: <>
        <Typography variant="h6"
          style={{
            color: ratingResolver(item.rating),
            textTransform: 'uppercase'
          }}>{item.rating}</Typography>
        <Typography variant="h6" >{item.title}</Typography>
        <Typography>{item.description}</Typography>
      </>,
      // primary: `${item.rating} - ${item.title}`,
      secondary: `${item.reportValueId}`,
    };
  }


  const handleClick = (id) => {
    dispatch(actions.showNew(id));
  }



  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Card>
          <CardHeader title="Insights" action={<Toolbar actions={actions} />}></CardHeader>
          { show && (
          <CardContent>
            {insights.map((ii, k) => (
              <Card key={k}>
                <CardHeader title={ii.category}></CardHeader>
                <CardContent>
                  <ItemList items={ii.items} itemMapper={itemMapper}
                    onClick={handleClick}
                    actionPath="/company-cluster-setting-report-insight/" />
                </CardContent>
              </Card>
            ))}
          </CardContent>
          )}
        </Card>
      </Grid>
    </Grid>
  );
};

export default CompanyClusterSettingReportInsight;



const ratingResolver = (rating) => {
  if (rating === 'alto') return "green";
  if (rating === 'medio') return "gray";
  if (rating === 'basso') return "red";
  return "yellow";
}



