export const constants = {


    SAVE_REQUEST: 'REPORT_USER_SAVE_REQUEST',
    SAVE_SUCCESS: 'REPORT_USER_SAVE_SUCCESS',
    SAVE_FAILURE: 'REPORT_USER_SAVE_FAILURE',
   
    GETALL_REQUEST: 'REPORT_USER_GETALL_REQUEST',
    GETALL_SUCCESS: 'REPORT_USER_GETALL_SUCCESS',
    GETALL_FAILURE: 'REPORT_USER_GETALL_FAILURE',

    GETONE_REQUEST: 'REPORT_USER_GETONE_REQUEST',
    GETONE_SUCCESS: 'REPORT_USER_GETONE_SUCCESS',
    GETONE_FAILURE: 'REPORT_USER_GETONE_FAILURE',

    DELETE_REQUEST: 'REPORT_USER_DELETE_REQUEST',
    DELETE_SUCCESS: 'REPORT_USER_DELETE_SUCCESS',
    DELETE_FAILURE: 'REPORT_USER_DELETE_FAILURE',

    SHOW_NEW : 'REPORT_USER_SHOW_NEW',
    CLOSE_DETAIL : 'REPORT_USER_CLOSE_DETAIL',
};
