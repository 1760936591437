//import { userService } from "../_services/user.service";

export default function handleResponse(response) {

    if (response.status === 401) {
        // userService.logout();
        localStorage.removeItem('user');
      //  window.location.reload(true);
    }

    return response.text().then(text => {
        const data = text && JSON.parse(text);
       
        if (!response.ok) {
            const error = (data) || response.statusText;
            return Promise.reject(error);
        }
        
        return data;
    });
}