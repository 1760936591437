import React, { useEffect } from 'react';
import { TextField } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { useState } from 'react';
import { actions } from '_model/screenshot';
import { useDispatch } from 'react-redux';

const useStyles = makeStyles({
  table: {
    // minWidth: 650,

  },
  cell: { 
    borderWidth: 0, 
    borderWidth: 1, 
    borderColor: 'lightgray', 
    borderStyle: 'dotted' 
  } 

});

const ScreenshotTable = ({ rows, screenshots }) => {

  const dispatch = useDispatch();

  const classes = useStyles();

  const [kpiNames, setKpiNames] = useState([]);

  const [data, setData] = useState([]);

  useEffect(() => {
    if (!screenshots) return;
    if (!Array.isArray(screenshots.items)) return;
    if (screenshots.items.length === 0) return;

    const headers = {};
    const data = {};


    screenshots.items.forEach((ss => {

      console.log("ss.kpis", Object.values(ss.kpis));
      Object.values(ss.kpis).forEach((kk) => {

        headers[kk.id] = {
          id: kk.id,
          name: kk.name,
        }

        if (data[ss._id] == undefined) {
          data[ss._id] = {
            period_name: ss.period_name,
            company_name: ss.company_name,

          }
        }

        data[ss._id][kk.id] = kk.value;


      });

    }));

    console.log('headers', headers);

    // const kn = screenshots.items[0].kpis.map((k)=> k.name);
    setKpiNames(Object.values(headers));

    console.log("data", data);
    setData(Object.values(data));
    // setData(screenshots.items);

  }, [screenshots]);

  const handleChange = (event) => {
    event.persist();

    if (event.type === 'keypress' && event.key !== 'Enter') {
      return;
    }
    const chunks = event.target.name.split('_');

    dispatch(actions.save({
      kpi_id: chunks.pop(),
      company_id: chunks.pop(),
      period_id: chunks.pop(),
      kpi_value: event.target.value,
    }));
  };


  return (

    <TableContainer component={Paper}>

      <Table className={classes.table} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>Period</TableCell>
            <TableCell>Company</TableCell>
            {kpiNames.map((v, k) => (
              <TableCell key={k} className={classes.cell} >{v.name}</TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((item, k) => (
            <TableRow key={k}>
              <TableCell component="th" scope="row">
                {item.period_name}
              </TableCell>
              <TableCell component="th" scope="row">
                {item.company_name}
              </TableCell>

              {kpiNames.map((kpi, k) => (
                <TableCell key={k} align="right" className={classes.cell}>
                  {item[kpi.id]}
                </TableCell>
              ))}
              {/* { item.kpis.map((kpi,k)=> (
                        <TableCell key={k} align="right">
                          <TextField 
                            name={`field_${item.period_id}_${item.company_id}_${kpi.id}`} 
                            variant="outlined" 
                            defaultValue={kpi.value} 
                            //onChange={handleChange}
                            
                            onKeyPress={handleChange}
                            // onBlur={handleChange}
                            />
                        </TableCell>
                      ))} */}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>

  );
};

export default ScreenshotTable;



