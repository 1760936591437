import React from 'react';
import { useParams } from 'react-router';
import CompanyClusterList, { CompanyClusterDetail, CompanyClusterView } from './components';

const CompanyCluster = ({ agency_id }) => {

  const { company_cluster_id } = useParams();



  return (
    <>

      {company_cluster_id ? (
        <CompanyClusterView id={company_cluster_id} />
      ) : (
          <>
            <CompanyClusterList agency_id={agency_id} />
            <CompanyClusterDetail />
          </>
        )}

    </>
  );

};

export default CompanyCluster;
