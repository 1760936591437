export const constants = {

    SAVE_REQUEST: 'CONFIG_PERIOD_SAVE_REQUEST',
    SAVE_SUCCESS: 'CONFIG_PERIOD_SAVE_SUCCESS',
    SAVE_FAILURE: 'CONFIG_PERIOD_SAVE_FAILURE',
   
    GETALL_REQUEST: 'CONFIG_PERIOD_GETALL_REQUEST',
    GETALL_SUCCESS: 'CONFIG_PERIOD_GETALL_SUCCESS',
    GETALL_FAILURE: 'CONFIG_PERIOD_GETALL_FAILURE',

    GETONE_REQUEST: 'CONFIG_PERIOD_GETONE_REQUEST',
    GETONE_SUCCESS: 'CONFIG_PERIOD_GETONE_SUCCESS',
    GETONE_FAILURE: 'CONFIG_PERIOD_GETONE_FAILURE',

    DELETE_REQUEST: 'CONFIG_PERIOD_DELETE_REQUEST',
    DELETE_SUCCESS: 'CONFIG_PERIOD_DELETE_SUCCESS',
    DELETE_FAILURE: 'CONFIG_PERIOD_DELETE_FAILURE',

    SHOW_NEW : 'CONFIG_PERIOD_SHOW_NEW',
    CLOSE_DETAIL : 'CONFIG_PERIOD_CLOSE_DETAIL',
};
