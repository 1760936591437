import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { actions } from '../../../_model/company_cluster_setting_report_value/actions';
import {  Card, CardContent, CardHeader, Grid } from '@material-ui/core';
import ItemList from 'common/ItemList';
import Toolbar from 'common/Toolbar';




const CompanyClusterSettingReportValue = ({agency_id}) => {
  
  const company_cluster_setting_report_value = useSelector(state => state.company_cluster_setting_report_value);
  const dispatch = useDispatch();
  
  // pagination
  const [rowsPerPage, setRowsPerPage] = useState(50);

  const [page, setPage] = useState(0);

  const handlePageChange = (event, page) => {
    setPage(page);
  };

  const handleRowsPerPageChange = event => {
    setPage(0);
    setRowsPerPage(event.target.value);
  };


  useEffect(() => {
    dispatch(actions.getAll({agency_id:agency_id}, page + 1, rowsPerPage));
  }, []);


  // item mapping



  const itemMapper = (item) => {
    return {
      id: item._id,
      action :  "/company-cluster-setting-report-value/" + item._id,
      primary: `${item.industry} - ${item.companyName}`,
      secondary: `#${item._id}`,
    };
  }


  const handleClick = (id) => {
    dispatch(actions.showNew(id));
  }

  const regenerareReport = () => {
   // dispatch(actions.createReport(item.settingId, item.companyId));
  }
  
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Card>
          <CardHeader title="Reports" action={<Toolbar actions={actions} />}></CardHeader>
          <CardContent>
            <ItemList items={company_cluster_setting_report_value.items ?? []} itemMapper={itemMapper}
              onClick={handleClick}
              actionPath="/company-cluster-setting-report-value/" />
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
};

export default CompanyClusterSettingReportValue;



