import React, { useState } from 'react';
import { useEffect } from 'react';
import { useParams } from 'react-router';
import CompanyClusterSettingReportInsightList, { CompanyClusterSettingReportInsightDetail, CompanyClusterSettingReportInsightView } from './components';

const CompanyClusterSettingReportInsight = ({reportValueId}) => {

  const { company_cluster_setting_report_insight_id } = useParams();

  return (
    <>
      {company_cluster_setting_report_insight_id ? (
        <CompanyClusterSettingReportInsightView id={company_cluster_setting_report_insight_id} />
      ) : (
          <>
            <CompanyClusterSettingReportInsightList reportValueId={reportValueId}/>
            <CompanyClusterSettingReportInsightDetail />
          </>
        )}

    </>
  );

};

export default CompanyClusterSettingReportInsight;
