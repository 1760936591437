

import React, { useEffect } from 'react';
import { Card, CardContent, CardHeader, Grid, Typography } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { actions } from '_model/company_cluster_setting_report_insight';
import { actions as filterActions } from '_model/filter';


const CompanyClusterSettingReportInsightView = ({ id }) => {

  const item = useSelector(state => state.company_cluster_setting_report_insight.item);

  const dispatch = useDispatch();
  
  useEffect(() => {
    if (!id) return;dispatch(actions.getOne(id));
    let filter = {};
    filter['company_cluster_setting_report_insight_id'] = id;
    dispatch(filterActions.addFilter(filter))

    return () => {
      dispatch(filterActions.removeFilter('company_cluster_setting_report_insight_id'));
    };
  }, [id]);

  if (!item) return null;

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Card>
          <CardHeader title="Report" />
          <CardContent>
            <Typography variant="h3">{item.companyName}</Typography>
            <Typography variant="h5">{item.clusterName}</Typography>
            <Typography variant="h6">{item.settingName}</Typography>
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs={12}>
        pppp
      </Grid>
      <Grid item xs={12}>
        zzzz
      </Grid>
    </Grid>
  );
}

export default CompanyClusterSettingReportInsightView;

