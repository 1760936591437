import { constants  } from './constants';
import { service } from './service';


export const actions = {
    login,
    logout,
};


function login(username, password) {
    
    return dispatch => {
        dispatch(request());

        service.login(username, password)
            .then(
                user => dispatch(success(user)),
                error => dispatch(failure(error))
            );
    };

    function request() { 
        return { type: constants.LOGIN_REQUEST } 
    }
    function success(user) { 
        
        localStorage.setItem('user', JSON.stringify(user));
        return { type: constants.LOGIN_SUCCESS, user } 
    }
    function failure(error) { 
        return { type: constants.LOGIN_FAILURE, error } 
    }
}




function logout() {
    
    return dispatch => {

        dispatch(success())
        
    };

    function request() { 
        return { type: constants.LOGOUT_REQUEST } 
    }
    function success() {
        localStorage.removeItem('user');
        return { type: constants.LOGOUT_SUCCESS } 
    }
    function failure(error) { 
        return { type: constants.LOGOUT_FAILURE, error } 
    }
}






